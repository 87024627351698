import React from 'react';
import './Clients.css';

// Import images directly
import client1 from '../images/clients/amstrong.jpeg';
import client2 from '../images/clients/gpj.png';
import client3 from '../images/clients/mci.jpg';
import client4 from '../images/clients/organic mandya.png';
import client5 from '../images/clients/thermax.png';
import client6 from '../images/clients/younion.jpeg';

const Clients = () => (
  <section id="clients">
    <h2>Our Happy Clients</h2>
    <div className="client-logos">
      <div className="client-logos-wrapper">
        {/* Use the imported image variables as src */}
        <img src={client1} alt="Client 1" />
        <img src={client2} alt="Client 2" />
        <img src={client3} alt="Client 3" />
        <img src={client4} alt="Client 4" />
        <img src={client5} alt="Client 5" />
        <img src={client6} alt="Client 6" />
        {/* Repeat the logos to create an infinite effect */}
        <img src={client1} alt="Client 1" />
        <img src={client2} alt="Client 2" />
        <img src={client3} alt="Client 3" />
        <img src={client4} alt="Client 4" />
        <img src={client5} alt="Client 5" />
        <img src={client6} alt="Client 6" />
        <img src={client1} alt="Client 1" />
        <img src={client2} alt="Client 2" />
        <img src={client3} alt="Client 3" />
        <img src={client4} alt="Client 4" />
        <img src={client5} alt="Client 5" />
        <img src={client6} alt="Client 6" />
      </div>
    </div>
  </section>
);

export default Clients;
