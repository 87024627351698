import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './FleetSection.css';

// Car Images
import audiQ7Image from '../images/car/Audi Q7 15000 .jpg';
import bmw7SeriesImage from '../images/car/bmw-7-series 17000.jpg';
import benzGLCImage from '../images/car/Benz GLC 10500.jpg';
import bmw5SeriesImage from '../images/car/BMW 5 series 12000.jpg';
import carnivalImage from '../images/car/Carnival 9000.avif';
import corollaAltisImage from '../images/car/Corolla Altis 4500.jpg';
import ertigaImage from '../images/car/Ertiga 1500.jpg';
import fortunerImage from '../images/car/Fortuner 7500.jpg';
import maybachImage from '../images/car/Maybach 31000.jpg';
import benzEClassImage from '../images/car/Mercedes-Benz_E-Class_11000.jpeg';
import benzSClassImage from '../images/car/mercedes-s-class 25000.jpg';
import rollsRoyceGhostImage from '../images/car/Rolls Royce Ghost 61000.jpg';
import toyotaEtiosImage from '../images/car/Toyota Etios 1100.jpg';
import toyotaCamryImage from '../images/car/Toyota_Camry_hybrid 9500.jpg';
import innovaCrystaImage from '../images/car/toyota-innova-crysta 1750.jpg';
import dz from '../images/car/dzire.jpeg';
// Van Images
import tempoTravellerImage from '../images/car/vans/Tempo-Traveller-3800.jpeg';
import toyotaCommuterImage from '../images/car/vans/toyota-commuter 14000.jpg';
import urbaniaImage from '../images/car/vans/urbania 9str 10,000.jpg';
import urbania16strImage from '../images/car/vans/urbania 16str 11,000.jpg';
import urbaniaLuxuryImage from '../images/car/vans/Urbania Luxury Minivan10str 10500.jpg';

// Mini Bus Images
import miniBus21Image from '../images/car/vans/Mini Bus/21str mini bus.jpg';
import miniBus25Image from '../images/car/vans/Mini Bus/25strminibus.jpg';
import miniBus32Image from '../images/car/vans/Mini Bus/93425_32 seater bus 1.jpg';

// Bus Images
import bus45SImage from '../images/car/vans/Mini Bus/Bus/45 seater buses 19,000.jpg';
import bus49SImage from '../images/car/vans/Mini Bus/Bus/49-seater-bus-hire-in-trivandrum 17,000.jpg';

const FleetSection = () => {
  const [activeCategory, setActiveCategory] = useState('all');

  const handleTabClick = (category) => {
    setActiveCategory(category);
  };

  return (
    <section className="fleet-section">
      <div className="tabs">
        <button
          className={`tab-btn ${activeCategory === 'cars' ? 'active' : ''}`}
          onClick={() => handleTabClick('cars')}
        >
          Cars
        </button>
        <button
          className={`tab-btn ${activeCategory === 'vans' ? 'active' : ''}`}
          onClick={() => handleTabClick('vans')}
        >
          Vans
        </button>
        <button
          className={`tab-btn ${activeCategory === 'minibuses' ? 'active' : ''}`}
          onClick={() => handleTabClick('minibuses')}
        >
          Mini Buses
        </button>
        <button
          className={`tab-btn ${activeCategory === 'buses' ? 'active' : ''}`}
          onClick={() => handleTabClick('buses')}
        >
          Buses
        </button>
      </div>

      <div className="fleet-container">
        {/* Cars Category */}
        <div className={`fleet-category ${activeCategory === 'all' || activeCategory === 'cars' ? 'active' : ''}`}>
          {/* Repeat the following vehicle card structure for all cars */}
          <div className="vehicle-card">
            <img src={toyotaEtiosImage} alt="Toyota Etios" />
            <div className="vehicle-info">
              <h3>Toyota Etios</h3>
              <p>Starting from ₹1,100/-</p>
              <p>Seats: 04</p>
              <p>Fuel Included</p>
              <Link to="/contact-us">
                <button className="contact-btn">Contact Us</button>
              </Link>
            </div>
          </div>
          <div className="vehicle-card">
            <img src={dz} alt="Ertiga" />
            <div className="vehicle-info">
              <h3>Swift Dzire</h3>
              <p>Starting from ₹1,100/-</p>
              <p>Seats: 04</p>
              <p>Fuel Included</p>
              <Link to="/contact-us">
                <button className="contact-btn">Contact Us</button>
              </Link>
            </div>
          </div>
          <div className="vehicle-card">
            <img src={ertigaImage} alt="Ertiga" />
            <div className="vehicle-info">
              <h3>Ertiga</h3>
              <p>Starting from ₹1,500/-</p>
              <p>Seats: 06</p>
              <p>Fuel Included</p>
              <Link to="/contact-us">
                <button className="contact-btn">Contact Us</button>
              </Link>
            </div>
          </div>
          <div className="vehicle-card">
            <img src={corollaAltisImage} alt="Corolla Altis" />
            <div className="vehicle-info">
              <h3>Corolla Altis</h3>
              <p>Starting from ₹4,500/-</p>
              <p>Seats: 04</p>
              <p>Fuel Included</p>
              <Link to="/contact-us">
                <button className="contact-btn">Contact Us</button>
              </Link>
            </div>
          </div>
          <div className="vehicle-card">
            <img src={fortunerImage} alt="Fortuner" />
            <div className="vehicle-info">
              <h3>Fortuner</h3>
              <p>Starting from ₹7,500/-</p>
              <p>Seats: 06</p>
              <p>Fuel Included</p>
              <Link to="/contact-us">
                <button className="contact-btn">Contact Us</button>
              </Link>
            </div>
          </div>
          <div className="vehicle-card">
            <img src={carnivalImage} alt="Carnival" />
            <div className="vehicle-info">
              <h3>Carnival</h3>
              <p>Starting from ₹9,000/-</p>
              <p>Seats: 06</p>
              <p>Fuel Included</p>
              <Link to="/contact-us">
                <button className="contact-btn">Contact Us</button>
              </Link>
            </div>
          </div>
          <div className="vehicle-card">
            <img src={toyotaCamryImage} alt="Toyota Camry Hybrid" />
            <div className="vehicle-info">
              <h3>Toyota Camry Hybrid</h3>
              <p>Starting from ₹9,500/-</p>
              <p>Seats: 04</p>
              <p>Fuel Included</p>
              <Link to="/contact-us">
                <button className="contact-btn">Contact Us</button>
              </Link>
            </div>
          </div>
          <div className="vehicle-card">
            <img src={innovaCrystaImage} alt="Toyota Innova Crysta" />
            <div className="vehicle-info">
              <h3>Toyota Innova Crysta</h3>
              <p>Starting from ₹1,750/-</p>
              <p>Seats: 07</p>
              <p>Fuel Included</p>
              <Link to="/contact-us">
                <button className="contact-btn">Contact Us</button>
              </Link>
            </div>
          </div>
          <div className="vehicle-card">
            <img src={benzGLCImage} alt="Benz GLC" />
            <div className="vehicle-info">
              <h3>Benz GLC</h3>
              <p>Starting from ₹10,500/-</p>
              <p>Seats: 04</p>
              <p>Fuel Included</p>
              <Link to="/contact-us">
                <button className="contact-btn">Contact Us</button>
              </Link>
            </div>
          </div>
          <div className="vehicle-card">
            <img src={benzEClassImage} alt="Mercedes-Benz E-Class" />
            <div className="vehicle-info">
              <h3>Mercedes-Benz E-Class</h3>
              <p>Starting from ₹11,000/-</p>
              <p>Seats: 04</p>
              <p>Fuel Included</p>
              <Link to="/contact-us">
                <button className="contact-btn">Contact Us</button>
              </Link>
            </div>
          </div>
          <div className="vehicle-card">
            <img src={bmw5SeriesImage} alt="BMW 5 Series" />
            <div className="vehicle-info">
              <h3>BMW 5 Series</h3>
              <p>Starting from ₹12,000/-</p>
              <p>Seats: 04</p>
              <p>Fuel Included</p>
              <Link to="/contact-us">
                <button className="contact-btn">Contact Us</button>
              </Link>
            </div>
          </div>
          <div className="vehicle-card">
            <img src={audiQ7Image} alt="Audi Q7" />
            <div className="vehicle-info">
              <h3>Audi Q7</h3>
              <p>Starting from ₹15,000/-</p>
              <p>Seats: 06</p>
              <p>Fuel Included</p>
              <Link to="/contact-us">
                <button className="contact-btn">Contact Us</button>
              </Link>
            </div>
          </div>
          <div className="vehicle-card">
            <img src={bmw7SeriesImage} alt="BMW 7 Series" />
            <div className="vehicle-info">
              <h3>BMW 7 Series</h3>
              <p>Starting from ₹17,000/-</p>
              <p>Seats: 04</p>
              <p>Fuel Included</p>
              <Link to="/contact-us">
                <button className="contact-btn">Contact Us</button>
              </Link>
            </div>
          </div>
          <div className="vehicle-card">
            <img src={benzSClassImage} alt="Mercedes-Benz S-Class" />
            <div className="vehicle-info">
              <h3>Mercedes-Benz S-Class</h3>
              <p>Starting from ₹25,000/-</p>
              <p>Seats: 04</p>
              <p>Fuel Included</p>
              <Link to="/contact-us">
                <button className="contact-btn">Contact Us</button>
              </Link>
            </div>
          </div>
          <div className="vehicle-card">
            <img src={maybachImage} alt="Maybach" />
            <div className="vehicle-info">
              <h3>Maybach</h3>
              <p>Starting from ₹31,000/-</p>
              <p>Seats: 04</p>
              <p>Fuel Included</p>
              <Link to="/contact-us">
                <button className="contact-btn">Contact Us</button>
              </Link>
            </div>
          </div>
          <div className="vehicle-card">
            <img src={rollsRoyceGhostImage} alt="Rolls Royce Ghost" />
            <div className="vehicle-info">
              <h3>Rolls Royce Ghost</h3>
              <p>Starting from ₹61,000/-</p>
              <p>Seats: 04</p>
              <p>Fuel Included</p>
              <Link to="/contact-us">
                <button className="contact-btn">Contact Us</button>
              </Link>
            </div>
          </div>
        </div>



          {/* Add more vehicle cards for the 'Cars' category */}
        </div>

        {/* Vans Category */}
        <div className={`fleet-category ${activeCategory === 'all' || activeCategory === 'vans' ? 'active' : ''}`}>
          {/* Repeat the following vehicle card structure for all vans */}
          <div className="vehicle-card">
            <img src={tempoTravellerImage} alt="Tempo Traveller" />
            <div className="vehicle-info">
              <h3>Tempo Traveller</h3>
              <p>Starting from ₹3,800/-</p>
              <p>Seats: 12-14</p>
              <p>Fuel Included</p>
              <Link to="/contact-us">
                <button className="contact-btn">Contact Us</button>
              </Link>
            </div>
          </div>
          <div className="vehicle-card">
            <img src={urbaniaImage} alt="Urbania 9 Seater" />
            <div className="vehicle-info">
              <h3>Urbania 9 Seater</h3>
              <p>Starting from ₹10,000/-</p>
              <p>Seats: 09</p>
              <p>Fuel Included</p>
              <Link to="/contact-us">
                <button className="contact-btn">Contact Us</button>
              </Link>
            </div>
          </div>
          <div className="vehicle-card">
            <img src={urbaniaLuxuryImage} alt="Urbania Luxury 10 Seater" />
            <div className="vehicle-info">
              <h3>Urbania Luxury 10 Seater</h3>
              <p>Starting from ₹10,500/-</p>
              <p>Seats: 10</p>
              <p>Fuel Included</p>
              <Link to="/contact-us">
                <button className="contact-btn">Contact Us</button>
              </Link>
            </div>
          </div>
          <div className="vehicle-card">
            <img src={urbania16strImage} alt="Urbania 16 Seater" />
            <div className="vehicle-info">
              <h3>Urbania 16 Seater</h3>
              <p>Starting from ₹11,000/-</p>
              <p>Seats: 16</p>
              <p>Fuel Included</p>
              <Link to="/contact-us">
                <button className="contact-btn">Contact Us</button>
              </Link>
            </div>
          </div>
          <div className="vehicle-card">
            <img src={toyotaCommuterImage} alt="Toyota Commuter" />
            <div className="vehicle-info">
              <h3>Toyota Commuter</h3>
              <p>Starting from ₹14,000/-</p>
              <p>Seats: 12</p>
              <p>Fuel Included</p>
              <Link to="/contact-us">
                <button className="contact-btn">Contact Us</button>
              </Link>
            </div>
          </div>
          {/* Add more vehicle cards for the 'Vans' category */}
        </div>
        <div className="fleet-container">
 
        {/* Mini Buses Category */}
        <div className={`fleet-category ${activeCategory === 'all' || activeCategory === 'minibuses' ? 'active' : ''}`}>
          {/* Repeat the following vehicle card structure for all mini buses */}
          <div className="vehicle-card">
              <img src={miniBus21Image} alt="21-Seater Mini Bus" />
              <div className="vehicle-info">
                <h3>21-Seater Mini Bus</h3>
                <p>Starting from ₹8,500/-</p>
                <p>Seats: 21</p>
                <p>Fuel Included</p>
                <Link to="/contact-us">
                  <button className="contact-btn">Contact Us</button>
                </Link>
              </div>
            </div>

            

            <div className="vehicle-card">
              <img src={miniBus32Image} alt="30-Seater Mini Bus" />
              <div className="vehicle-info">
                <h3>30-Seater Mini Bus</h3>
                <p>Starting from ₹11,000/-</p>
                <p>Seats: 30</p>
                <p>Fuel Included</p>
                <Link to="/contact-us">
                  <button className="contact-btn">Contact Us</button>
                </Link>
              </div>
            </div>

            <div className="vehicle-card">
              <img src={miniBus25Image} alt="25-Seater Tempo Traveller" />
              <div className="vehicle-info">
                <h3>25-Seater Mini Bus</h3>
                <p>Starting from ₹12,500/-</p>
                <p>Seats: 25</p>
                <p>Fuel Included</p>
                <Link to="/contact-us">
                  <button className="contact-btn">Contact Us</button>
                </Link>
              </div>
            </div>
            
          {/* Add more vehicle cards for the 'Mini Buses' category */}
        </div>

        {/* Buses Category */}
        <div className={`fleet-category ${activeCategory === 'all' || activeCategory === 'buses' ? 'active' : ''}`}>
          {/* Repeat the following vehicle card structure for all buses */}
          <div className="vehicle-card">
            <img src={bus45SImage} alt="45 Seater Bus" />
            <div className="vehicle-info">
              <h3>45 Seater Bus</h3>
              <p>Starting from ₹19,000/-</p>
              <p>Seats: 45</p>
              <p>Fuel Included</p>
              <Link to="/contact-us">
                <button className="contact-btn">Contact Us</button>
              </Link>
            </div>
          </div>
          <div className="vehicle-card">
            <img src={bus49SImage} alt="49 Seater Bus" />
            <div className="vehicle-info">
              <h3>49 Seater Bus</h3>
              <p>Starting from ₹17,000/-</p>
              <p>Seats: 49</p>
              <p>Fuel Included</p>
              <Link to="/contact-us">
                <button className="contact-btn">Contact Us</button>
              </Link>
            </div>
          </div>
          {/* Add more vehicle cards for the 'Buses' category */}
        </div>
      </div>
    </section>
  );
};

export default FleetSection;
