import React from 'react';
import './Services.css';
import TechnologySection from './TechnologySection';
import ImageSection from './ImageSection';
import employeeTransportationIcon from '../images/icons/Employee-Transportation.png';
import airportTransferIcon from '../images/icons/Airport-Transfer.png';
import localOutstationRentalsIcon from '../images/icons/asking-lift.png';
import eventConferenceTransfersIcon from '../images/icons/Local-or-Outstation-Car-Rental.png';

function Services() {
  return (
    <div className="services-page">
      <h2>Our Services</h2>
      <p>Sun Car Rentals Services For Every Occasion</p>

      <div className="service-cards">
        <div className="service-card">
          <img src={employeeTransportationIcon} alt="Employee Transportation" />
          <h3>Employee Transportation</h3>
          <p>Sun Car rentals Services Private Ltd is a competent name among Bangalore corporate transport services.</p>
        </div>
        <div className="service-card">
          <img src={airportTransferIcon} alt="Airport Transport" />
          <h3>Airport Transport</h3>
          <p>Ideal for Airport Transportation, so you never face any commuting difficulty.</p>
        </div>
        <div className="service-card">
          <img src={localOutstationRentalsIcon} alt="Local/Outstation Rentals" />
          <h3>Local/Outstation Rentals</h3>
          <p>We provide an unmatched fleet for local & outstation rentals.</p>
        </div>
        <div className="service-card">
          <img src={eventConferenceTransfersIcon} alt="Shuttle Service" />
          <h3>Event and Conference Transfers</h3>
          <p>We provide Event and Conference Transfers for the convenience of our customers.</p>
        </div>
      </div>

      <TechnologySection />
      <ImageSection />
    </div>
  );
}

export default Services;
