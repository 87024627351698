import React from 'react';
import { Link } from 'react-router-dom';
import footerImage from '../images/footer.png'; // Corrected relative path

import './Footer.css';

const Footer = () => (
  <footer>
    <br></br>
        <p>&copy; 2024 Sun Car rentals. All rights reserved.</p>
    <div className="footer-links">
      <p>
        Employee Transport | 
        Local/Outstation Travel | 
        Event and Conference Transfers | 
        Airport Transfers
      </p>
      <p>Contact: <a href="mailto:suncarrentals@hotmail.com">suncarrentals@hotmail.com</a> | Phone: +91 9110680458</p>
    </div>
  </footer>
);

export default Footer;
