import React from 'react';
import './Metrics.css';

// Import images for the metrics
import experienceImage from '../images/fleets/Experience.png'; // Example image
import usersImage from '../images/fleets/Trips.png'; // Example image
import tripsImage from '../images/fleets/Users-2.png'; // Example image

const Metrics = () => (
  <section id="metrics">
    <div className="metric">
      <img src={experienceImage} alt="Years of Experience" className="metric-image" />
      <h2>30</h2>
      <p>Years of Experience</p>
    </div>
    <div className="metric">
      <img src={usersImage} alt="Users" className="metric-image" />
      <h2>100K+</h2>
      <p>Users</p>
    </div>
    <div className="metric">
      <img src={tripsImage} alt="Trips Everyday" className="metric-image" />
      <h2>100</h2>
      <p>Trips Everyday</p>
    </div>
  </section>
);

export default Metrics;
