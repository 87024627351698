import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Header from './components/Header';
import Banner from './components/Banner';
import Metrics from './components/Metrics';
import Highlights from './components/Highlights';
import Clients from './components/Clients';
import Karunadu from './components/Karunadu'; // Import the Karunadu component
import Contact from './components/Contact';
import Footer from './components/Footer';
import Services from './components/Services';
import FleetSection from './components/FleetSection';
import ContactUs from './components/ContactUs';
import SustainabilityPage from './components/SustainabilityPage';  // Import SustainabilityPage

import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={
            <>
              <Banner />
              <Metrics />
              <Highlights />
              <Clients />
              <Karunadu /> {/* Place Karunadu component after Clients */}
              <Contact />
            </>
          } />
          <Route path="/services" element={<Services />} />
          <Route path="/fleet" element={<FleetSection />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/karunadu" element={<Karunadu />} />
          <Route path="/sustainability" element={<SustainabilityPage />} /> {/* Add this route */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
