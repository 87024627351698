import React from 'react';
import './Highlights.css';

const Highlights = () => (
  <section id="highlights">
    <div className="highlight"><h3>24/7 Support</h3><p>Round-the-clock support and customer care.</p></div>
    <div className="highlight"><h3>Certified Drivers</h3><p>All drivers are certified and ensure passenger safety.</p></div>
    {/* Add more highlights as needed */}
  </section>
);

export default Highlights;
