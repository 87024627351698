import React, { useState } from 'react';
import './ContactUs.css'; // Make sure to create a corresponding CSS file
import emailjs from 'emailjs-com';

const ContactUs = () => {
  // Set up state to manage form inputs
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(e.target); // Check the form data in the console
    
    // Send the email with the form data using EmailJS
    emailjs.sendForm('service_yqr7ku4', 'template_etbvjfm', e.target, 'kzB2-4UA2frXYfqDM')
      .then((result) => {
        alert('Message Sent Successfully!');
        setFormData({
          name: '',
          email: '',
          phone: '',
          message: ''
        }); // Clear the form after sending
      }, (error) => {
        alert('Message Sending Failed. Please try again later.');
        console.log(error.text);
      });
  };

  return (
    <section className="contact-section">
      <div className="contact-form">
        <h2>Contact Us</h2>
        <p>Get in touch with us for any inquiries or assistance.</p>

        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input 
              type="text" 
              id="name" 
              name="name"  // This matches {{from_name}} in the template
              value={formData.name} 
              onChange={handleInputChange} 
              required 
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input 
              type="email" 
              id="email" 
              name="email"  // This matches {{from_email}} in the template
              value={formData.email} 
              onChange={handleInputChange} 
              required 
            />
          </div>

          <div className="form-group">
            <label htmlFor="phone">Phone Number</label>
            <input 
              type="tel" 
              id="phone" 
              name="phone"  // This matches {{from_phone}} in the template
              value={formData.phone} 
              onChange={handleInputChange} 
              required 
              placeholder="e.g. +1 234 567 890" 
            />
          </div>

          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea 
              id="message"
              name="message"  // This matches {{message}} in the template
              value={formData.message} 
              onChange={handleInputChange} 
              required 
            ></textarea>
          </div>

          <button type="submit">Submit</button>
        </form>
      </div>

      <div className="contact-info">
        <h3>Corporate Office</h3>
        <p><strong>Contact</strong></p>
        <p>+91 9110680458</p>
        <p><strong>Email</strong></p>
        <p>suncarrentals@hotmail.com</p>
        
        <p><strong>Address</strong></p>
        <p>#33/3, 1st main road, S.R nagar Bangalore 560-027</p>
      </div>
    </section>
  );
};

export default ContactUs;
