import React from 'react';
import './SustainabilityPage.css'; // Make sure this CSS file is correctly linked

const SustainabilityPage = () => {
  return (
    <div className="sustainability-page">
      <section className="hero-section">
        <div className="hero-text">
          <h1>Driving Towards a Greener Future</h1>
          <p>Our commitment to sustainability is not just a choice—it's a responsibility.</p>
        </div>
      </section>

      <section className="roadmap-section">
        <h2>Our Roadmap to Sustainability</h2>
        <div className="roadmap">
          <div className="roadmap-item">
            <i className="fa fa-car"></i>
            <h3>Eco-Friendly Fleet</h3>
            <p>Introducing hybrid and electric vehicles (EVs) into our fleet to reduce carbon emissions.</p>
          </div>
          <div className="roadmap-item">
            <i className="fa fa-leaf"></i>
            <h3>Reducing Our Carbon Footprint</h3>
            <p>Optimizing routing technology to minimize fuel consumption and drive efficiency.</p>
          </div>
          <div className="roadmap-item">
            <i className="fa fa-recycle"></i>
            <h3>Sustainable Operations</h3>
            <p>Going paperless and reducing energy usage in our offices and service centers.</p>
          </div>
        </div>
      </section>

      <section className="collaboration-section">
        <h2>Collaborating for Impact</h2>
        <p>
          We partner with environmental organizations to support reforestation and
          conservation initiatives.
        </p>
        <p className="collaboration-section-footer">
          Join us in making a difference. Together, we can create a world where every
          journey leaves a positive impact.
        </p>
      </section>

      {/* <footer className="sustainability-footer">
        <p>Join us in making a difference. Together, we can create a world where every journey leaves a positive impact.</p>
      </footer> */}
    </div>
  );
};

export default SustainabilityPage;
