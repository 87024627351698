import React from 'react';
import './TechnologySection.css';
import mobileIcon from '../images/icons/mobile.png';
import auditIcon from '../images/icons/audit.png';
import tripsheetIcon from '../images/icons/tripsheet.png';
import vehicleIcon from '../images/icons/vehicle.png';
import occupancyIcon from '../images/icons/occupancy.png';
import maskingIcon from '../images/icons/masking.png';
import safeDropIcon from '../images/icons/safe-drop.png';
import billingIcon from '../images/icons/billing.png';

function TechnologySection() {
  const technologies = [
    {
      icon: mobileIcon,
      title: 'Web/Mobile Rostering',
    },
    {
      icon: auditIcon,
      title: 'Audit & Forensics',
    },
    {
      icon: tripsheetIcon,
      title: 'E-Tripsheets',
    },
    {
      icon: vehicleIcon,
      title: 'Vehicle Tracking',
    },
    {
      icon: occupancyIcon,
      title: 'Real-Time Occupancy Monitoring',
    },
    {
      icon: maskingIcon,
      title: 'Employee Number Masking',
    },
    {
      icon: safeDropIcon,
      title: 'Automated Safe Drop Confirmation',
    },
    {
      icon: billingIcon,
      title: 'Automated Billing',
    },
  ];

  return (
    <section className="technology-section">
      <h2>Our Technology</h2>
      <p>Innovative Solutions for Efficient Operations</p>
      <div className="technology-grid">
        {technologies.map((technology, index) => (
          <div key={index} className="technology-item">
            <img src={technology.icon} alt={technology.title} />
            <p>{technology.title}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default TechnologySection;
