import React from 'react';
import './ImageSection.css'; // Import the CSS file for styling
import trafficImage from '../images/icons/traffic.png'; // Import the image

function ImageSection() {
  return (
    <div className="image-section">
      <div className="image-container">
        <img src={trafficImage} alt="Traffic Image" /> {/* Use the imported image */}
      </div>
      <div className="text-container">
        <ul>
          <li>Dashboard</li>
          <li>Trip Sheet Comparison</li>
          <li>Delay Report</li>
          <li>Occupancy Chart</li>
          <li>Billing</li>
          <li>Web Access / Manager</li>
          <li>App</li>
          <li>View Alerts</li>
          <li>Monitor Real-Time Movement Of Vehicles</li>
        </ul>
        <ul>
          <li>Shift Wise Statistics</li>
          <li>Unavailed / No Shows</li>
          <li>Vendor Performance</li>
          <li>Vehicle Utility</li>
          <li>Chargeback</li>
          <li>History Replay</li>
          <li>Track Device Health</li>
          <li>Filter Vehicles By Escort</li>
          <li>Trips / In Campus</li>
        </ul>
      </div>
    </div>
  );
}

export default ImageSection;
