import React, { useState } from 'react';
import './Contact.css';
import emailjs from 'emailjs-com';

const Contact = () => {
  // State to handle form inputs
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    enquiry: ''
  });

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Send form data to EmailJS
    emailjs.sendForm('service_s0iqmua', 'template_t15k7it', e.target, 'OW9YyoFzRfRbICz55')
      .then((result) => {
        alert('Request Sent Successfully!');
        setFormData({
          name: '',
          email: '',
          phone: '',
          enquiry: ''
        }); // Clear form after submission
      }, (error) => {
        alert('Message Sending Failed. Please try again.');
        console.log(error.text);
      });
  };

  return (
    <section id="contact">
      <h2>Request a Callback</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Your Name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          required
        />
        <input
          type="email"
          placeholder="Your Email ID"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          required
        />
        <input
          type="text"
          placeholder="Contact Number"
          name="phone"
          value={formData.phone}
          onChange={handleInputChange}
          required
        />
        <textarea
          placeholder="Your Enquiry"
          name="enquiry"
          value={formData.enquiry}
          onChange={handleInputChange}
          required
        ></textarea>
        <button type="submit">Send Request</button>
      </form>
    </section>
  );
};

export default Contact;
