import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from '../images/logo.jpeg'; // Import your logo

const Header = () => (
  <header>
    <div className="logo">
      <Link to="/">
        <img src={logo} alt="Cab Service Logo" />
      </Link>
    </div>
    <nav>
      <ul>
        <li><Link to="/" className="nav-link">Home</Link></li> {/* This will link to the root ("/") */}
        <li><Link to="/services" className="nav-link">Services</Link></li>
        <li><Link to="/fleet" className="nav-link">Fleet</Link></li>
        <li><Link to="/sustainability" className="nav-link">Sustainability</Link></li>
        <li><Link to="/contact-us" className="nav-link">Contact Us</Link></li>
        <li><Link to="/contact-us" className="quote-button">Get a Quote</Link></li>
      </ul>
    </nav>
  </header>
);

export default Header;
