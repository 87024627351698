import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './Banner.css';

const Banner = () => (
  <section id="banner">
    <h1>Transport Service in Bangalore</h1>
    <p>Your trusted partner for transportation, no matter the event.</p>
    <Link to="/services">
      <button className="cta-button">Know More</button>
    </Link>
  </section>
);

export default Banner;
