import React from 'react';
import './Karunadu.css'; // Import the CSS file for styling
import supportImage from '../images/Services-home/24-7-Support.jpg';
import operationsImage from '../images/Services-home/Transparent-Operations.jpg';
import costEffectiveImage from '../images/Services-home/Cost-Effective.jpg';
import certifiedDriversImage from '../images/Services-home/Certified-Drivers.jpg';
import safetyImage from '../images/Services-home/Passengers-Safety.jpg';
import transportationManagementImage from '../images/Services-home/Employee-Transportation-Management.jpg';
import mobilitySolutionsImage from '../images/Services-home/Mobility-Solutions.jpg';
import occupancyMonitoringImage from '../images/Services-home/Real-Time-Occupancy-Monitoring.jpg';
import safeDropImage from '../images/Services-home/Automated-Safe-Drop-Confirmation.jpg';
import trackingImage from '../images/Services-home/Vehicle-Tracking.jpg';
import billingImage from '../images/Services-home/Automated-Billing.jpg';
import rosteringImage from '../images/Services-home/Web-Mobile-Rostering.jpg';

const Karunadu = () => {
  const features = [
    {
      title: '24/7 Support',
      description: 'Vehicle breakdowns? Driver issues? Worry no more! Sun Car Rentals offers round-the-clock support and customer care.',
      imageSrc: supportImage,
    },
    {
      title: 'Transparent Operations',
      description: 'With our transparent mode of operation, we bring in consistency and financial predictability, hence the operation remains smooth and unhindered.',
      imageSrc: operationsImage,
    },
    {
      title: 'Cost Effective',
      description: 'At Sun Car Rentals, we aim to provide high-quality transportation services in Bangalore at an affordable price. We ensure giving quality service with effective cost solutions.',
      imageSrc: costEffectiveImage,
    },
    {
      title: 'Certified Drivers',
      description: 'All the drivers associated with Sun Car Rentals Services are certified and ensure to take care of passengers’ safety and regulations.',
      imageSrc: certifiedDriversImage,
    },
    {
      title: 'Passengers Safety',
      description: 'With our top priority being passenger safety and our safety policies in place, you can rest at ease while we take care of dropping or picking up your employees at their premises.',
      imageSrc: safetyImage,
    },
    {
      title: 'Employee Transportation Management',
      description: 'Sun Car Rentals helps in stress-free commutes, focusing on the safety of your employees to give your company improved productivity in the office.',
      imageSrc: transportationManagementImage,
    },
    {
      title: 'Mobility Solutions',
      description: 'One-stop solution for anything and everything your business needs. Sun Car Rentals offers you a planned and better price to give you an excellent mobility solution with our transportation services in Bangalore across your business.',
      imageSrc: mobilitySolutionsImage,
    },
    {
      title: 'Real-time Occupancy Monitoring',
      description: 'Monitoring cab occupancy is always important. It’s our responsibility to follow government social distancing guidelines to maintain a safe environment for your employees.',
      imageSrc: occupancyMonitoringImage,
    },
    {
      title: 'Automated Safe Drop Confirmation',
      description: 'With reliable drivers and safety features during trip execution, our technology ensures complete safety during drop-offs for your employees.',
      imageSrc: safeDropImage,
    },
    {
      title: 'Vehicle Tracking',
      description: 'Track the movement of each vehicle in a fleet as they move from one destination to another.',
      imageSrc: trackingImage,
    },
    {
      title: 'Automated Billing',
      description: 'Everything is concise and clear. You will receive legal bills of places you have traveled to, with details of the driver and car used for the trip.',
      imageSrc: billingImage,
    },
    {
      title: 'Web/Mobile Rostering',
      description: 'Know the exact location, ETA, and automated cab arrival status. Sun Car Rentals ensures a seamless commute experience for your employees with user-friendly apps.',
      imageSrc: rosteringImage,
    },
  ];

  return (
    <div className="karunadu-container">
      <h2>Why Sun Car Rentals?</h2>
      <p>
        Sun Car Rentals Services is one of the pioneers in providing cab services in Bangalore for employee transportation.
        Sun Car Rentals Service is an end-to-end solution to manage, automate, and streamline transport operations in your companies.
      </p>
      <div className="feature-cards">
        {features.map((feature, index) => (
          <div key={index} className="feature-card">
            <img src={feature.imageSrc} alt={feature.title} className="feature-image" />
            <div className="feature-content">
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Karunadu;
